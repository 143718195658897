
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Chart from 'chart.js/auto';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
  
@Options({})
export default class BarChartVue extends Vue {
    
    @Prop() dataDictionary: { [key: string]: OM.KeyValuePairOfStringAndDouble[]; };
    @Prop() colors: string[] = [];
    @Prop() managingScopes: boolean;
  
    mounted() {
        this.init();
    }
  
    init() {
        const labels = Object.keys(this.dataDictionary); // Estrai le etichette (es. Headoffice, Depot, ecc.)
        const keys = Array.from(
            new Set(
                labels.flatMap((label) =>
                    this.dataDictionary[label].map((item) => item.key)
                )
            )
        );

        const datasets = keys.map((scope, index) => {
            return {
                label: scope,
                data: labels.map((label) => {
                    const item = this.dataDictionary[label].find((x) => x.key === scope);
                    return item ? item.value : 0;
                }),
                backgroundColor: this.getColor(index),
                borderColor: this.getColor(index),
                borderWidth: 1,
            };
        });
    
        // Creazione del grafico
        const ctx = (this.$refs.barChart as HTMLCanvasElement).getContext("2d");
        new Chart(ctx!, {
            type: "bar",
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: "bottom",
                    },
                },
                scales: {
                    x: {
                        stacked: true, // Rendi le barre raggruppate
                    },
                    y: {
                        stacked: true,
                    },
                },
            },
        });
    }
  
    getColor(index: number) {
        if(this.managingScopes) {
            const scopesColors = [VM.ScopesHexColors.Scope1, VM.ScopesHexColors.Scope2, VM.ScopesHexColors.Scope3];
            return scopesColors[index % scopesColors.length];
        }
        else
            return this.colors[index];
    }
  
    // getBorderColor(index: number) {
    //     const colors = ["#16a085", "#2980b9", "#8e44ad"];
    //     return colors[index % colors.length];
    // }
}
