
import { GHGSurveyClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: { },
})
export default class MySurveys extends Vue {

    surveyList: OM.MySurveysVM[] = [];

    created() {
        this.init();
    }

    init() {
        GHGSurveyClient.getCompanySurveys()
        .then(x => {
            this.surveyList = x;
        })
    }

    getGroupedGarments(products: OM.KeyValuePairOfStringAndString[]) {
        const groupedStrings = Object.values(
            products.reduce<Record<string, Set<string>>>((acc, pair) => {
                if (!acc[pair.value]) {
                    acc[pair.value] = new Set(); // Usa un Set per garantire univocità
                }
                acc[pair.value].add(pair.value); // Aggiungi i valori al Set
                return acc;
            }, {})
        ).map(group => Array.from(group).join(", "));
        return groupedStrings;
    }

}
