import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-4" }
const _hoisted_4 = { class: "col-sm-4" }
const _hoisted_5 = {
  key: 0,
  class: "col-sm-4"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-4" }
const _hoisted_8 = {
  key: 1,
  class: "form-group w-100"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-4" }
const _hoisted_11 = { class: "col-sm-4" }
const _hoisted_12 = { class: "col-sm-4" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-sm-4" }
const _hoisted_15 = { class: "col-sm-4" }
const _hoisted_16 = { class: "col-sm-4" }
const _hoisted_17 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_form_field, {
              type: "select-string",
              list: _ctx.timeFrameList,
              modelValue: _ctx.timeFrameSelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.timeFrameSelected) = $event)),
              labelKey: "Time frame",
              labelValue: "Time frame",
              placeholderKey: "Select a time frame",
              placeholderValue: "Select a time frame"
            }, null, 8, ["list", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_field, {
              type: "text",
              modelValue: _ctx.saveModel.year,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.saveModel.year) = $event)),
              labelKey: "Year",
              labelValue: "Year",
              placeholderKey: "Year",
              placeholderValue: "Year"
            }, null, 8, ["modelValue"])
          ]),
          (_ctx.timeFrameSelected == _ctx.viewModel.TimeFrame.Month)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_form_field, {
                  type: "select",
                  list: _ctx.monthList,
                  modelValue: _ctx.saveModel.month,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.saveModel.month) = $event)),
                  labelKey: "Month",
                  labelValue: "Month",
                  placeholderKey: "Month",
                  placeholderValue: "Month"
                }, null, 8, ["list", "modelValue"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.premises.length > 0)
              ? (_openBlock(), _createBlock(_component_form_field, {
                  key: 0,
                  type: "select",
                  list: _ctx.premises,
                  modelValue: _ctx.saveModel.selectedPremises.identifier,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.saveModel.selectedPremises.identifier) = $event)),
                  labelKey: "Premises type",
                  labelValue: "Premises type",
                  placeholderKey: "Select a premises type",
                  placeholderValue: "Select a premises type"
                }, null, 8, ["list", "modelValue"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Premises type",
                    text: "Premises type",
                    class: "input_title d-block fw_700 mb-2"
                  }),
                  (_ctx.premises.length == 0)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn btn-primary",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addPremises()))
                      }, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Add new premises",
                          text: "Add new premises"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_form_field, {
              type: "select-string",
              list: _ctx.categories,
              modelValue: _ctx.scope1EmissionData.category,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.scope1EmissionData.category) = $event)),
              labelKey: "Category",
              labelValue: "Category",
              placeholderKey: "Select a category",
              placeholderValue: "Select a category",
              onChange: _ctx.categoryUpdated
            }, null, 8, ["list", "modelValue", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_form_field, {
              type: "select-string",
              list: _ctx.fuelEquipmentTypes,
              modelValue: _ctx.scope1EmissionData.fuelEquipmentType,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.scope1EmissionData.fuelEquipmentType) = $event)),
              labelKey: "Fuel / Equipment type",
              labelValue: "Fuel / Equipment type",
              placeholderKey: "Fuel / Equipment type",
              placeholderValue: "Fuel / Equipment type",
              onChange: _ctx.fuelEquipmentTypeUpdated
            }, null, 8, ["list", "modelValue", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_form_field, {
              type: "select-string",
              list: _ctx.fuelNames,
              modelValue: _ctx.scope1EmissionData.fuelName,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.scope1EmissionData.fuelName) = $event)),
              labelKey: "Fuel name",
              labelValue: "Fuel name",
              placeholderKey: "Fuel name",
              placeholderValue: "Fuel name",
              onChange: _ctx.fuelNameUpdated
            }, null, 8, ["list", "modelValue", "onChange"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_form_field, {
              type: "number",
              step: 0.1,
              modelValue: _ctx.saveModel.consumption,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.saveModel.consumption) = $event)),
              labelKey: "Consumption",
              labelValue: "Consumption",
              placeholderKey: "Consumption",
              placeholderValue: "Consumption"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_form_field, {
              type: "select-string",
              list: _ctx.uoms,
              modelValue: _ctx.scope1EmissionData.uom,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.scope1EmissionData.uom) = $event)),
              labelKey: "Unit",
              labelValue: "Unit",
              placeholderKey: "Unit",
              placeholderValue: "Unit"
            }, null, 8, ["list", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_form_field, {
              type: "select-string",
              list: _ctx.sources,
              modelValue: _ctx.scope1EmissionData.source,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.scope1EmissionData.source) = $event)),
              labelKey: "Source",
              labelValue: "Source",
              placeholderKey: "Source",
              placeholderValue: "Source"
            }, null, 8, ["list", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("button", {
            class: "btn btn-tertiary px-5",
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.calculateAndSave())),
            disabled: _ctx.calculateDisabled
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Calculate & Record",
              text: "Calculate & Record"
            })
          ], 8, _hoisted_18)
        ])
      ]))
    : _createCommentVNode("", true)
}