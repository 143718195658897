import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-4" }
const _hoisted_4 = { class: "col-sm-4" }
const _hoisted_5 = {
  key: 0,
  class: "col-sm-4"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-4" }
const _hoisted_8 = {
  key: 1,
  class: "form-group w-100"
}
const _hoisted_9 = { class: "col-sm-4" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-4" }
const _hoisted_13 = { class: "col-sm-4" }
const _hoisted_14 = { class: "col-sm-4" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-sm-4" }
const _hoisted_17 = { class: "col-sm-4" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-sm-4" }
const _hoisted_21 = {
  key: 0,
  class: "col-sm-4"
}
const _hoisted_22 = {
  key: 1,
  class: "col-sm-4"
}
const _hoisted_23 = {
  key: 2,
  class: "col-sm-4"
}
const _hoisted_24 = {
  key: 3,
  class: "col-sm-4"
}
const _hoisted_25 = {
  key: 0,
  class: "row"
}
const _hoisted_26 = { class: "col-sm-4" }
const _hoisted_27 = { class: "col-sm-4" }
const _hoisted_28 = { class: "col-sm-4" }
const _hoisted_29 = { class: "col-sm-4" }
const _hoisted_30 = {
  key: 1,
  class: "row"
}
const _hoisted_31 = { class: "col-sm-4" }
const _hoisted_32 = { class: "col-sm-4" }
const _hoisted_33 = { key: 2 }
const _hoisted_34 = { class: "row" }
const _hoisted_35 = { class: "col-sm-4" }
const _hoisted_36 = { class: "col-sm-4" }
const _hoisted_37 = { class: "col-sm-4" }
const _hoisted_38 = { class: "row" }
const _hoisted_39 = { class: "col-sm-4" }
const _hoisted_40 = { class: "col-sm-4" }
const _hoisted_41 = { class: "col-sm-4" }
const _hoisted_42 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_43 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_form_field, {
              type: "select-string",
              list: _ctx.timeFrameList,
              modelValue: _ctx.timeFrameSelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.timeFrameSelected) = $event)),
              labelKey: "Time frame",
              labelValue: "Time frame",
              placeholderKey: "Select a time frame",
              placeholderValue: "Select a time frame"
            }, null, 8, ["list", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_field, {
              type: "text",
              modelValue: _ctx.saveModel.year,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.saveModel.year) = $event)),
              labelKey: "Year",
              labelValue: "Year",
              placeholderKey: "Year",
              placeholderValue: "Year"
            }, null, 8, ["modelValue"])
          ]),
          (_ctx.timeFrameSelected == _ctx.viewModel.TimeFrame.Month)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_form_field, {
                  type: "select",
                  list: _ctx.monthList,
                  modelValue: _ctx.saveModel.month,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.saveModel.month) = $event)),
                  labelKey: "Month",
                  labelValue: "Month",
                  placeholderKey: "Month",
                  placeholderValue: "Month"
                }, null, 8, ["list", "modelValue"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.premises.length > 0)
              ? (_openBlock(), _createBlock(_component_form_field, {
                  key: 0,
                  type: "select",
                  list: _ctx.premises,
                  modelValue: _ctx.saveModel.selectedPremises.identifier,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.saveModel.selectedPremises.identifier) = $event)),
                  labelKey: "Premises type",
                  labelValue: "Premises type",
                  placeholderKey: "Select a premises type",
                  placeholderValue: "Select a premises type"
                }, null, 8, ["list", "modelValue"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Premises type",
                    text: "Premises type",
                    class: "input_title d-block fw_700 mb-2"
                  }),
                  (_ctx.premises.length == 0)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn btn-primary",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addPremises()))
                      }, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Add new premises",
                          text: "Add new premises"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_form_field, {
              type: "select-string",
              list: _ctx.categories,
              modelValue: _ctx.scope3EmissionData.category,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.scope3EmissionData.category) = $event)),
              labelKey: "Category",
              labelValue: "Category",
              placeholderKey: "Select a category",
              placeholderValue: "Select a category",
              onChange: _ctx.categoryUpdated
            }, null, 8, ["list", "modelValue", "onChange"])
          ])
        ]),
        (_ctx.isTransportationSelected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.transportation_fuelEquipmentTypes,
                    modelValue: _ctx.scope3EmissionData.fuelEquipmentType,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.scope3EmissionData.fuelEquipmentType) = $event)),
                    labelKey: "Fuel / Equipment type",
                    labelValue: "Fuel / Equipment type",
                    placeholderKey: "Select a fuel / equipment type",
                    placeholderValue: "Select a fuel / equipment type",
                    onChange: _ctx.transportation_fuelEquipmentTypeUpdated
                  }, null, 8, ["list", "modelValue", "onChange"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.transportation_fuelNames,
                    modelValue: _ctx.scope3EmissionData.fuelName,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.scope3EmissionData.fuelName) = $event)),
                    labelKey: "Fuel name",
                    labelValue: "Fuel name",
                    placeholderKey: "Fuel name",
                    placeholderValue: "Fuel name"
                  }, null, 8, ["list", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_form_field, {
                    type: "number",
                    step: 0.1,
                    modelValue: _ctx.saveModel.consumption,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.saveModel.consumption) = $event)),
                    labelKey: "Consumption",
                    labelValue: "Consumption",
                    placeholderKey: "Consumption",
                    placeholderValue: "Consumption"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.transportation_uoms,
                    modelValue: _ctx.scope3EmissionData.uom,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.scope3EmissionData.uom) = $event)),
                    labelKey: "Unit",
                    labelValue: "Unit",
                    placeholderKey: "Unit",
                    placeholderValue: "Unit"
                  }, null, 8, ["list", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.transportation_sources,
                    modelValue: _ctx.scope3EmissionData.source,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.scope3EmissionData.source) = $event)),
                    labelKey: "Source",
                    labelValue: "Source",
                    placeholderKey: "Source",
                    placeholderValue: "Source"
                  }, null, 8, ["list", "modelValue"])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isTravelSelected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.travel_JourneyModes,
                    modelValue: _ctx.scope3EmissionData.journeyMode,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.scope3EmissionData.journeyMode) = $event)),
                    labelKey: "Journey mode",
                    labelValue: "Journey mode",
                    placeholderKey: "Select a journey mode",
                    placeholderValue: "Select a journey mode",
                    onChange: _ctx.travel_journeyModeUpdated
                  }, null, 8, ["list", "modelValue", "onChange"])
                ]),
                (_ctx.isByAirFlights)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createVNode(_component_form_field, {
                        type: "select-string",
                        list: _ctx.travel_FlightTypes,
                        modelValue: _ctx.scope3EmissionData.flightType,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.scope3EmissionData.flightType) = $event)),
                        labelKey: "Flight type",
                        labelValue: "Flight type",
                        placeholderKey: "Select a flight type",
                        placeholderValue: "Select a flight type",
                        onChange: _ctx.travel_flightTypeUpdated
                      }, null, 8, ["list", "modelValue", "onChange"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isByAirFlights)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createVNode(_component_form_field, {
                        type: "select-string",
                        list: _ctx.travel_PassengerTypes,
                        modelValue: _ctx.scope3EmissionData.passengerType,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.scope3EmissionData.passengerType) = $event)),
                        labelKey: "Passenger type",
                        labelValue: "Passenger type",
                        placeholderKey: "Select a passenger type",
                        placeholderValue: "Select a passenger type"
                      }, null, 8, ["list", "modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isBySeaFerry)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _createVNode(_component_form_field, {
                        type: "select-string",
                        list: _ctx.travel_BySeaFerryPassengerTypes,
                        modelValue: _ctx.scope3EmissionData.passengerType,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.scope3EmissionData.passengerType) = $event)),
                        labelKey: "Passenger type",
                        labelValue: "Passenger type",
                        placeholderKey: "Select a passenger type",
                        placeholderValue: "Select a passenger type"
                      }, null, 8, ["list", "modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isBySeaFerry)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createVNode(_component_form_field, {
                        type: "number",
                        step: 0.1,
                        modelValue: _ctx.saveModel.consumption,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.saveModel.consumption) = $event)),
                        labelKey: "Consumption",
                        labelValue: "Consumption",
                        placeholderKey: "Consumption",
                        placeholderValue: "Consumption"
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isByAirFlights)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_component_form_field, {
                        type: "select-string",
                        list: _ctx.travel_RadiactiveForcing,
                        modelValue: _ctx.scope3EmissionData.radiativeForcing,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.scope3EmissionData.radiativeForcing) = $event)),
                        labelKey: "Radiative forcing (RF)",
                        labelValue: "Radiative forcing (RF)",
                        placeholderKey: "Select a radiative forcing (RF)",
                        placeholderValue: "Select a radiative forcing (RF)"
                      }, null, 8, ["list", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_form_field, {
                        type: "number",
                        step: 0.1,
                        modelValue: _ctx.saveModel.consumption,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.saveModel.consumption) = $event)),
                        labelKey: "Consumption",
                        labelValue: "Consumption",
                        placeholderKey: "Consumption",
                        placeholderValue: "Consumption"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_form_field, {
                        type: "select-string",
                        list: _ctx.travel_uoms,
                        modelValue: _ctx.scope3EmissionData.uom,
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.scope3EmissionData.uom) = $event)),
                        labelKey: "Unit",
                        labelValue: "Unit",
                        placeholderKey: "Unit",
                        placeholderValue: "Unit"
                      }, null, 8, ["list", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_form_field, {
                        type: "select-string",
                        list: _ctx.travel_sources,
                        modelValue: _ctx.scope3EmissionData.source,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.scope3EmissionData.source) = $event)),
                        labelKey: "Source",
                        labelValue: "Source",
                        placeholderKey: "Source",
                        placeholderValue: "Source"
                      }, null, 8, ["list", "modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isBySeaFerry)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createVNode(_component_form_field, {
                        type: "select-string",
                        list: _ctx.travel_BySeaFerryUoms,
                        modelValue: _ctx.scope3EmissionData.uom,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.scope3EmissionData.uom) = $event)),
                        labelKey: "Unit",
                        labelValue: "Unit",
                        placeholderKey: "Unit",
                        placeholderValue: "Unit"
                      }, null, 8, ["list", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_form_field, {
                        type: "select-string",
                        list: _ctx.travel_BySeaFerrySources,
                        modelValue: _ctx.scope3EmissionData.source,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.scope3EmissionData.source) = $event)),
                        labelKey: "Source",
                        labelValue: "Source",
                        placeholderKey: "Source",
                        placeholderValue: "Source"
                      }, null, 8, ["list", "modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isFuelAndEnergySelected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.fuelEnergy_fuelClassifications,
                    modelValue: _ctx.scope3EmissionData.fuelClassification,
                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.scope3EmissionData.fuelClassification) = $event)),
                    labelKey: "Fuel classification",
                    labelValue: "Fuel classification",
                    placeholderKey: "Select a fuel classification",
                    placeholderValue: "Select a fuel classification",
                    onChange: _ctx.fuelClassificationUpdated
                  }, null, 8, ["list", "modelValue", "onChange"])
                ]),
                _createElementVNode("div", _hoisted_36, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.fuelEnergy_fuelEquipmentTypes,
                    modelValue: _ctx.scope3EmissionData.fuelEquipmentType,
                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.scope3EmissionData.fuelEquipmentType) = $event)),
                    labelKey: "Fuel / Equipment type",
                    labelValue: "Fuel / Equipment type",
                    placeholderKey: "Select a fuel / equipment type",
                    placeholderValue: "Select a fuel / equipment type",
                    onChange: _ctx.fuelEnergy_EquipmentTypeUpdated
                  }, null, 8, ["list", "modelValue", "onChange"])
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.fuelEnergy_fuelNames,
                    modelValue: _ctx.scope3EmissionData.fuelName,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.scope3EmissionData.fuelName) = $event)),
                    labelKey: "Fuel name",
                    labelValue: "Fuel name",
                    placeholderKey: "Fuel name",
                    placeholderValue: "Fuel name",
                    onChange: _ctx.fuelEnergy_FuelNameUpdated
                  }, null, 8, ["list", "modelValue", "onChange"])
                ])
              ]),
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createVNode(_component_form_field, {
                    type: "number",
                    step: 0.1,
                    modelValue: _ctx.saveModel.consumption,
                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.saveModel.consumption) = $event)),
                    labelKey: "Consumption",
                    labelValue: "Consumption",
                    placeholderKey: "Consumption",
                    placeholderValue: "Consumption"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_40, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.fuelEnergy_uoms,
                    modelValue: _ctx.scope3EmissionData.uom,
                    "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.scope3EmissionData.uom) = $event)),
                    labelKey: "Unit",
                    labelValue: "Unit",
                    placeholderKey: "Unit",
                    placeholderValue: "Unit"
                  }, null, 8, ["list", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createVNode(_component_form_field, {
                    type: "select-string",
                    list: _ctx.fuelEnergy_sources,
                    modelValue: _ctx.scope3EmissionData.source,
                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.scope3EmissionData.source) = $event)),
                    labelKey: "Source",
                    labelValue: "Source",
                    placeholderKey: "Source",
                    placeholderValue: "Source"
                  }, null, 8, ["list", "modelValue"])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("button", {
            class: "btn btn-tertiary px-5",
            onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.calculateAndSave())),
            disabled: _ctx.calculateDisabled
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Calculate & Record",
              text: "Calculate & Record"
            })
          ], 8, _hoisted_43)
        ])
      ]))
    : _createCommentVNode("", true)
}