
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ActivityDataRecorderClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({
    components: { },
})
export default class Scope1ActivityRecorderDetail extends Vue {

    @Prop({
        default: () => new OM.ActivityDataRecorder()
    }) activityData: OM.ActivityDataRecorder;

    localReferenceID: string = "";

    created() {
        this.localReferenceID = this.activityData.referenceID;
    }

    updateReferenceID() {
        var vm = new OM.UpdateActivityReferenceIdVM();
        vm.activityIdentifier = this.activityData.identifier;
        vm.referenceID = this.localReferenceID;

        ActivityDataRecorderClient.updateReferenceID(vm)
        .then(x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("Success", "Success"),
                subtitle: this.$localizationService.getLocalizedValue("Reference ID updated successfully", "Reference ID updated successfully"),
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: this.$localizationService.getLocalizedValue("Already existing an activity with this reference ID", "Already existing an activity with this reference ID"),
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

}
