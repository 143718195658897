
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class MultipleActivitiesDeleteModal extends Vue {

    @Prop({
        default: () => []
    }) availableScopes: string[];
    
    @Prop({
        default: () => []
    }) availableYears: string[];

    @Prop({
        default: () => []
    }) availablePremises: string[];

    @Prop({
        default: () => []
    }) availableCategories: string[];

    @Prop() callback: any;

    model: OM.ActivitiesMultipleDeleteVM = new OM.ActivitiesMultipleDeleteVM();

    created() {}

    get disabled() {
        return this.model.years.length == 0 && this.model.scopes.length == 0 && this.model.premises.length == 0 && this.model.categories.length == 0;
    }

    toggleYear(year: string) {
        var yearAsNumber = parseInt(year);
        var existingIndex = this.model.years.findIndex(x => x == yearAsNumber);
        if(existingIndex == -1)
            this.model.years.push(yearAsNumber);
        else
            this.model.years.splice(existingIndex, 1);
    }
    toggleScope(scope: string) {
        var existingIndex = this.model.scopes.findIndex(x => x == scope);
        if(existingIndex == -1)
            this.model.scopes.push(scope);
        else
            this.model.scopes.splice(existingIndex, 1);
    }

    togglePremises(premises: string) {
        var existingIndex = this.model.premises.findIndex(x => x == premises);
        if(existingIndex == -1)
            this.model.premises.push(premises);
        else
            this.model.premises.splice(existingIndex, 1);
    }

    toggleCategory(category: string) {
        var existingIndex = this.model.categories.findIndex(x => x == category);
        if(existingIndex == -1)
            this.model.categories.push(category);
        else
            this.model.categories.splice(existingIndex, 1);
    }

    deleteSelected() {
        ActivityDataRecorderClient.multipleDeleting(this.model)
        .then(x => {
            this.callback();
        })
    }
    deleteAll() {
        ActivityDataRecorderClient.deleteAll()
        .then(x => {
            this.callback();
        })
    }
    
}
