
import { Options, Vue } from 'vue-class-component';
import { EmployeeClient, GHGSurveyClient } from '@/services/Services';
import SurveyQuestions from '../components/surveyQuestions.vue';
import * as OM from '@/Model';

@Options({
    components: {
        SurveyQuestions
    },
})
export default class EditSurvey extends Vue {

    step: number = 1;
    availableLicences: OM.AvailableLicensesVM[] = [];
    estimationFilter: OM.GHGEstimationsFilterVM = new OM.GHGEstimationsFilterVM();
    remainingEstimations: OM.KeyValuePairOfStringAndString[] = [];

    surveyConfigCreation: OM.GHGCreateNewSurveyAnswerVM = new OM.GHGCreateNewSurveyAnswerVM();
    surveyQuestions: OM.SurveyQuestion = new OM.SurveyQuestion();
    userSurveyId: string = "";
    userSurvey: OM.SurveyResponse = new OM.SurveyResponse();

    created() {
        var surveyAnswerId = this.$route.params.id ? this.$route.params.id.toString() : null;
        this.init(surveyAnswerId);
    }

    init(surveyAnswerId: string) {
        var proms = [];

        proms.push(EmployeeClient.getAvailableLicenses());
        proms.push(GHGSurveyClient.getMyEstimationRemaining(this.estimationFilter));
        proms.push(GHGSurveyClient.getGHGSurveyQuestions());

        if(surveyAnswerId)
            proms.push(GHGSurveyClient.getSurveyAnswerById(surveyAnswerId));

        Promise.all(proms)
        .then(xs => {
            this.availableLicences = xs[0];
            this.remainingEstimations = xs[1];
            this.surveyQuestions = xs[2];

            if(surveyAnswerId) {
                this.userSurveyId = surveyAnswerId;
                this.userSurvey = xs[3];
                this.surveyConfigCreation.products = xs[3].products;
                this.step = 2;
            }
        })
    }

    toggleGarmentAsFilter(garment: string) {
        var findIndex = this.estimationFilter.garments.findIndex(x => x == garment);
        if(findIndex == -1)
            this.estimationFilter.garments.push(garment);
        else
            this.estimationFilter.garments.splice(findIndex, 1);

        this.filterAvailableEstimations();
    }
    filterByGarmentIsActive(garment: string) {
        return this.estimationFilter.garments.findIndex(x => x == garment) > -1;
    }

    filterAvailableEstimations() {
        GHGSurveyClient.getMyEstimationRemaining(this.estimationFilter)
        .then(x => {
            this.remainingEstimations = x;
        })
    }

    toggleEstimation(sku: string, garment: string) {
        var findIndex = this.surveyConfigCreation.products.findIndex(x => x.key == sku);
        if(findIndex == -1) {
            var newKeyValuePair = new OM.KeyValuePairOfStringAndString();
            newKeyValuePair.key = sku;
            newKeyValuePair.value = garment;
            this.surveyConfigCreation.products.push(newKeyValuePair);
        }
        else {
            this.surveyConfigCreation.products.splice(findIndex, 1);
        }
    }
    estimationIsSelected(sku: string) {
        return this.surveyConfigCreation.products.findIndex(x => x.key == sku) > -1;
    }
    selectAllEstimations() {
        this.surveyConfigCreation.products = [];
        this.remainingEstimations.forEach(element => {
            var newKeyValuePair = new OM.KeyValuePairOfStringAndString();
            newKeyValuePair.key = element.key;
            newKeyValuePair.value = element.value;
            this.surveyConfigCreation.products.push(newKeyValuePair);
        });
    }
    removeAllEstimations() {
        this.surveyConfigCreation.products = [];
    }

    createSurvey() {
        GHGSurveyClient.createNewSurveyAnswer(this.surveyConfigCreation)
        .then(x => {
            this.userSurvey = x;
            this.userSurveyId = x.identifier;
            this.step = 2;
            var myDiv = document.getElementById('formContainer');
            myDiv.scrollTop = 0;
        })
    }
}
