
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Chart from 'chart.js/auto';
import * as OM from '@/Model';
  
@Options({})
export default class BarChartHorizontalVue extends Vue {
    
    @Prop() dataDictionary: { [key: string]: OM.KeyValuePairOfStringAndDouble[]; };
    @Prop() colors: string[] = [];
  
    mounted() {
        this.init();
    }
  
    init() {
        const labels = Object.keys(this.dataDictionary); // Estrai le etichette (es. Headoffice, Depot, ecc.)
        const keys = Array.from(
            new Set(
                labels.flatMap((label) =>
                    this.dataDictionary[label].map((item) => item.key)
                )
            )
        );

        const datasets = keys.map((scope, index) => {
            return {
                label: scope,
                axis: 'y',
                data: labels.map((label) => {
                    const item = this.dataDictionary[label].find((x) => x.key === scope);
                    return item ? item.value : 0;
                }),
                backgroundColor: this.getColor(index),
                borderColor: this.getColor(index),
                borderWidth: 1,
            };
        });
    
        // Creazione del grafico
        const ctx = (this.$refs.barChartHorizontal as HTMLCanvasElement).getContext("2d");
        new Chart(ctx!, {
            type: "bar",
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        position: "bottom",
                    },
                },
                indexAxis: 'y',
                scales: {
                    x: {
                        stacked: true, // Rendi le barre raggruppate
                    },
                    y: {
                        stacked: true,
                    },
                },
            },
        });
    }
  
    getColor(index: number) {
        return this.colors[index];
    }
}
