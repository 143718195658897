import { RouteRecordRaw, RouterView } from 'vue-router';
import { h } from 'vue';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/ghg-survey-list',
            name: 'ghg-survey-list',
            component: () => import("./views/mySurveys.vue")
        },
        {
            path: '/ghg-survey/:id?',
            name: 'ghg-survey',
            component: () => import("./views/editSurvey.vue")
        }
    ];
};