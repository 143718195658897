
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ImportConfigurationsClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({})
export default class TransportUploadExcelModal extends Vue {

    file: File = null;

    @Prop() callback: any;

    created() {
    }

    uploadFile() {
        ImportConfigurationsClient.importaTransportsConfigurationFromExcel(this.file)
        .then(x => {
            this.callback();
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: this.$localizationService.getLocalizedValue("Something went wrong", "Something went wrong"),
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.callback();
                }
            })
        })
    }
    
}
