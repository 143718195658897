
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { GHGSurveyClient, GarmentClient } from '@/services/Services';
// import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({})
export default class SurveyQuestions extends Vue {
    
    @Prop() surveyQuestions: OM.SurveyQuestion;
    @Prop() userSurvey: OM.SurveyResponse;
    @Prop() userSurveyId: string;

    activeQuestions: any = {};
    answeredQuestions: any = {};

    phasePerGarment: any = {};
    selectedPhasesPerGarment: OM.GHGSurveyAnswerGarmentPhaseVM[] = [];

    showSuccessMessage: boolean = false;
    
    mounted() {
        GarmentClient.getProductionPhasesForEachGarment(this.groupedGarments)
        .then(x => {
            this.phasePerGarment = x;
            this.initializeSurvey();
        })
    }

    initializeSurvey() {
        this.activeQuestions = this.surveyQuestions.categories.reduce((acc, category) => {
            // Prendi la prima domanda della categoria (non ha restrizioni iniziali)
            if(this.userSurvey.categoryResponses.findIndex(x => x.categoryName == category.name) == -1) {
                acc[category.name] = category.questions[0] || null;
            }
            return acc;
        }, {});

        this.answeredQuestions = this.surveyQuestions.categories.reduce((acc, category) => {
            acc[category.name] = []; // Inizializza lo storico vuoto
            return acc;
        }, {});

        if(this.userSurvey.categoryResponses.length > 0) {
            this.userSurvey.categoryResponses.forEach(element => {
                element.questionResponses.forEach(response => {
                    const category = this.surveyQuestions.categories.find(c => c.name === element.categoryName);
                    const completeQuestion = category.questions.find(q => q.id === response.questionId);

                    var question = new OM.GHGSurveyQuestions();
                    question.id = response.questionId;
                    question.text = response.questionText;
                    question.nextQuestionIdIfYes = completeQuestion.nextQuestionIdIfYes;
                    question.nextQuestionIdIfNo = completeQuestion.nextQuestionIdIfNo;
                    question.isFinal = completeQuestion.isFinal;
                    question.finalLogicIfYes = completeQuestion.finalLogicIfYes;
                    question.finalLogicIfNo = completeQuestion.finalLogicIfNo;
                    question.showProductionPhasesForEachGarment = completeQuestion.showProductionPhasesForEachGarment;

                    var answer = response.answer;
                    var destination = answer ? question.finalLogicIfYes : question.finalLogicIfNo;
                    var destinationAlternative = !answer ? question.finalLogicIfYes : question.finalLogicIfNo;
                    
                    const nextQuestionId = answer
                    ? completeQuestion.nextQuestionIdIfYes
                    : completeQuestion.nextQuestionIdIfNo;
                    
                    const nextQuestion = category.questions.find(q => q.id === nextQuestionId);
                    var hasNextQuestion = nextQuestion != null;
                    var showProductionPhasesForEachGarment = question.showProductionPhasesForEachGarment && answer;

                    this.answeredQuestions[element.categoryName].push({
                        ...question,
                        answer,
                        destination,
                        destinationAlternative,
                        hasNextQuestion,
                        showProductionPhasesForEachGarment
                    });

                    response.garmentsDestinationForProductionPhases.forEach(garment => {
                        garment.value.forEach(phase => {
                            var phaseForGarmentModel = new OM.GHGSurveyAnswerGarmentPhaseVM();
                            phaseForGarmentModel.surveyId = this.userSurveyId;
                            phaseForGarmentModel.categoryName = element.categoryName;
                            phaseForGarmentModel.garment = garment.key;
                            phaseForGarmentModel.phase = phase;
                            phaseForGarmentModel.questionId = question.id;
                            this.selectedPhasesPerGarment.push(phaseForGarmentModel)
                        });
                    });
                });
            });
        }
    }

    answerQuestion(categoryName, answer) {
        const currentQuestion = this.activeQuestions[categoryName];
        if (!currentQuestion) 
            return;

        // Determina la prossima domanda basandosi sulla risposta
        const nextQuestionId = answer
            ? currentQuestion.nextQuestionIdIfYes
            : currentQuestion.nextQuestionIdIfNo;

        const category = this.surveyQuestions.categories.find(c => c.name === categoryName);
        const nextQuestion = category.questions.find(q => q.id === nextQuestionId);

        // Aggiorna lo stato delle domande attive
        this.activeQuestions[categoryName] = nextQuestion || null;

        var destination = answer ? currentQuestion.finalLogicIfYes : currentQuestion.finalLogicIfNo;
        var destinationAlternative = !answer ? currentQuestion.finalLogicIfYes : currentQuestion.finalLogicIfNo;
        var hasNextQuestion = nextQuestion != null;
        var showProductionPhasesForEachGarment = currentQuestion.showProductionPhasesForEachGarment && answer;
        // Aggiungi la domanda al registro delle risposte
        this.answeredQuestions[categoryName].push({
            ...currentQuestion,
            answer,
            destination,
            destinationAlternative,
            hasNextQuestion,
            showProductionPhasesForEachGarment
        });

        this.saveAnswerToDbAndCheckIfSurveyCompleted(answer, categoryName, currentQuestion);
    }

    changeAnswer(categoryName, index, newAnswer) {
        // Rimuovi tutte le risposte successive
        this.answeredQuestions[categoryName] = this.answeredQuestions[categoryName].slice(0, index + 1);

        // Determina la nuova domanda attiva
        const currentQuestion = this.answeredQuestions[categoryName][index];
        const nextQuestionId = newAnswer
            ? currentQuestion.nextQuestionIdIfYes
            : currentQuestion.nextQuestionIdIfNo;

        const category = this.surveyQuestions.categories.find(c => c.name === categoryName);
        const nextQuestion = category.questions.find(q => q.id === nextQuestionId);
        const originalQuestion = category.questions.find(q => q.id === currentQuestion.id);

        this.activeQuestions[categoryName] = nextQuestion || null;

        // Aggiorna la risposta della domanda selezionata
        var destination = newAnswer ? currentQuestion.finalLogicIfYes : currentQuestion.finalLogicIfNo;
        var destinationAlternative = !newAnswer ? currentQuestion.finalLogicIfYes : currentQuestion.finalLogicIfNo;
        this.answeredQuestions[categoryName][index].destination = destination;
        this.answeredQuestions[categoryName][index].destinationAlternative = destinationAlternative;
        this.answeredQuestions[categoryName][index].answer = newAnswer;
        this.answeredQuestions[categoryName][index].hasNextQuestion = nextQuestion != null;
        this.answeredQuestions[categoryName][index].showProductionPhasesForEachGarment = originalQuestion.showProductionPhasesForEachGarment && newAnswer;

        this.saveAnswerToDbAndCheckIfSurveyCompleted(newAnswer, categoryName, currentQuestion);
    }

    saveAnswerToDbAndCheckIfSurveyCompleted(answer: boolean, category: string, currentQuestion: OM.GHGSurveyQuestions) {
        var answerQuestionModel = new OM.GHGSurveyAnswerVM();
        answerQuestionModel.surveyAnswerId = this.userSurveyId;
        answerQuestionModel.answer = answer;
        answerQuestionModel.category = category;
        answerQuestionModel.questionId = currentQuestion.id;
        answerQuestionModel.questionText = currentQuestion.text;

        if (currentQuestion.isFinal) {
            answerQuestionModel.finalLogic = answer ? currentQuestion.finalLogicIfYes : currentQuestion.finalLogicIfNo;
            answerQuestionModel.finalLogicOtherPhases = !answer ? currentQuestion.finalLogicIfYes : currentQuestion.finalLogicIfNo;
        }

        GHGSurveyClient.answerToQuestion(answerQuestionModel)
        .then(x => {
            if(x) {
                this.showSuccessMessage = true;
                setTimeout(() => {
                    this.showSuccessMessage = false;
                }, 10000);
            }
            // if(x) {
            //     this.$opModal.show(InfoModal, {
            //         text: this.$localizationService.getLocalizedValue("Congratulation", "Congratulation"),
            //         subtitle: this.$localizationService.getLocalizedValue("You answered all the questions, you can find the results of the calculation in the GHG Inventory", "You answered all the questions, you can find the results of the calculation in the GHG Inventory"),
            //         confirm: this.$localizationService.getLocalizedValue("Got it", "Got it"),
            //         confirmCb: () => {
            //             this.$opModal.closeLast();
            //             this.$router.replace('/ghg-survey-list');
            //         }
            //     })
            // }
        })
    }

    get groupedGarments() {
        var garmentFilterModel = new OM.GarmentListForFilterVM();

        const groupedStrings = Object.values(
            this.userSurvey.products.reduce<Record<string, Set<string>>>((acc, pair) => {
                if (!acc[pair.value]) {
                    acc[pair.value] = new Set(); // Usa un Set per garantire univocità
                }
                acc[pair.value].add(pair.value); // Aggiungi i valori al Set
                return acc;
            }, {})
        ).map(group => Array.from(group).join(", "));

        garmentFilterModel.garments = groupedStrings;
        console.log("sdfhljksdl", this.userSurvey.products)
        console.log("garmentFilterModel", garmentFilterModel);

        return garmentFilterModel;
    }

    togglePhasesForGarment(answered: any, category: string, garment: string, phase: string) {
        var phaseForGarmentModel = new OM.GHGSurveyAnswerGarmentPhaseVM();
        phaseForGarmentModel.surveyId = this.userSurveyId;
        phaseForGarmentModel.categoryName = category;
        phaseForGarmentModel.garment = garment;
        phaseForGarmentModel.phase = phase;
        phaseForGarmentModel.questionId = answered.id;

        GHGSurveyClient.togglePhasesForGarment(phaseForGarmentModel, false)
        .then(x => {
            var findIndex = this.selectedPhasesPerGarment.findIndex(x => x.categoryName == category && x.garment == garment && x.phase == phase && x.questionId == answered.id);

            if(findIndex > -1)
                this.selectedPhasesPerGarment.splice(findIndex, 1);
            else
                this.selectedPhasesPerGarment.push(phaseForGarmentModel);
        })
    }

    checkIfActive(answered: any, category: string, garment: string, phase: string) {
        return this.selectedPhasesPerGarment.findIndex(x => x.categoryName == category && x.garment == garment && x.phase == phase && x.questionId == answered.id) > -1;
    }

}
