import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import begin from '@/views/begin';
import store from '@/store';
import garment from '@/views/garment';
import electricity from '@/views/electricity';
import transport from '@/views/transport';
import calculator from '@/views/calculator';
import rawMaterial from '@/views/rawMaterial';
import productionProcess from '@/views/productionProcess';
import companies from '@/views/companies';
import productionPhase from '@/views/productionPhase';
import dashboard from '@/views/dashboard';
import servicesSold from '@/views/servicesSold';
import servicesSoldCatalog from '@/views/servicesSoldCatalog';
import servicesHistory from '@/views/servicesHistory';
import bestCombination from '@/views/bestCombination';
import providers from '@/views/providers';
import scope12 from '@/views/scope12';
import ghgSurvey from '@/views/ghgSurvey';

const routes: Array<RouteRecordRaw> = [
    ...begin.routes(),
    {
        path: '/excel-guide',
        name: 'excel-guide',
        component: () => import("../views/excelGuide.vue")
    },
    {
        path: '/',
        component: () => import("../views/layout.vue"),
        beforeEnter: (to, from) => {
            if(!store.state.loggedUser.identifier)
                return false
        },
        children: [
            //backoffice 😲
            ...garment.routes(),
            ...transport.routes(),
            ...electricity.routes(),
            ...rawMaterial.routes(),
            ...productionPhase.routes(),
            ...productionProcess.routes(),
            ...servicesSold.routes(),
            ...companies.routes(),
        ]
    },
    {
        path: '/',
        component: () => import("../views/customerLayout.vue"),
        beforeEnter: (to, from) => {
            if(!store.state.loggedUser.identifier)
                return false
        },
        children: [
            //calculator 🐱‍👤
            ...calculator.routes(),
            ...dashboard.routes(),
            ...providers.routes(),
            ...bestCombination.routes(),
            ...servicesSoldCatalog.routes(),
            ...servicesHistory.routes(),
            ...scope12.routes(),
            ...ghgSurvey.routes(),
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition){
        window.scrollTo({ 
            left: savedPosition ? savedPosition.left : 0, 
            top: savedPosition ? savedPosition.top : 0,
            behavior: <any>'instant' //⚡
        });
    }
})

export default router