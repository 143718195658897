
import { CompanyClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import AddNewPremisesModal from '../modals/addNewPremisesModal.vue';
import * as OM from '@/Model';

@Options({
    components: { },
})
export default class Premises extends Vue {

    premises: OM.Premises[] = [];

    created() {
        this.init();
    }

    init() {
        CompanyClient.getPremisesByCompany()
        .then(x => {
            this.premises = x;
        })
    }

    addOrEditPremises(existingPremises: OM.Premises | null) {
        this.$opModal.show(AddNewPremisesModal, {
            premises: existingPremises,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
