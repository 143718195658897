
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import ActivityDataDetailModal from '../modals/activityDataDetailModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import MultipleActivitiesDeleteModal from '../modals/multipleActivitiesDeleteModal.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: { },
})
export default class DataInventory extends Vue {

    datas: OM.ActivityDataRecorderListVM[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        ActivityDataRecorderClient.getAllByCompany()
        .then(x => {
            this.datas = x;
            
            setTimeout(() => {
                this.loaded = true;
            }, 500);
        })
    }

    openDetail(itemId: string) {
        ActivityDataRecorderClient.getById(itemId)
        .then(x => {
            this.$opModal.show(ActivityDataDetailModal, {
                activityData: x
            })
        })
    }

    get availableScopes() {
        var allScopes = this.datas.map(x => x.scope);
        return allScopes.filter((value, index, array) => array.indexOf(value) === index);
    }
    get availableYears() {
        var allYears = this.datas.map(x => x.year);
        return allYears.filter((value, index, array) => array.indexOf(value) === index);
    }
    get availablePremises() {
        var allPremises = this.datas.map(x => x.premisesType);
        return allPremises.filter((value, index, array) => array.indexOf(value) === index);
    }
    get availableCategories() {
        var allCategories = this.datas.map(x => x.category);
        return allCategories.filter((value, index, array) => array.indexOf(value) === index);
    }

    get filterScopes() {
        var ris = [];
        ris.push("");

        this.availableScopes.forEach(element => {
            ris.push(element);
        });

        return ris;
    }
    get filterYears() {
        var ris = [];
        ris.push("");

        this.availableYears.forEach(element => {
            ris.push(element);
        });

        return ris;
    }
    get filterPremises() {
        var ris = [];
        ris.push("");

        this.availablePremises.forEach(element => {
            ris.push(element);
        });

        return ris;
    }
    get filterCategories() {
        var ris = [];
        ris.push("");

        this.availableCategories.forEach(element => {
            ris.push(element);
        });

        return ris;
    }
    get filterMonth() {
        var ris = [];

        ris.push("");
        ris.push("Year-wise");
        var months = Object.keys(VM.Months);
        months.forEach(element => {
            ris.push(element);
        });

        return ris;
    }
    get filterSources() {
        var ris = [];
        ris.push("");

        var allSources = this.datas.map(x => x.source);
        var uniqueSources = allSources.filter((value, index, array) => array.indexOf(value) === index);
        uniqueSources.forEach(element => {
            ris.push(element);
        });

        return ris;
    }

    openMultipleDeleteModal() {
        this.$opModal.show(MultipleActivitiesDeleteModal, {
            availableScopes: this.availableScopes,
            availableYears: this.availableYears,
            availablePremises: this.availablePremises,
            availableCategories: this.availableCategories,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    deleteActivity(itemId: string) {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("Attention", "Attention"),
            subtitle: this.$localizationService.getLocalizedValue("Are you sure you want to delete this item?", "Are you sure you want to delete this item?"),
            confirm: this.$localizationService.getLocalizedValue("Delete item", "Delete item"),
            confirmCb: () => {
                ActivityDataRecorderClient.deleteById(itemId)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            },
            deny: this.$localizationService.getLocalizedValue("Avoid", "Avoid"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

}
