
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, GarmentClient, RawMaterialClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class ExcelGuide extends Vue {

    countries: OM.AcceptableElectricityEmissionVM[] = [];
    garments: string[] = [];
    garmentsWithProperties: OM.GroupedGarmentsByPropertiesVM[] = [];
    rawMaterials: string[] = [];

    created() {
        Promise.all([
            ElectricityEmissionClient.getAcceptableCountries(),
            GarmentClient.getAllGarments(),
            GarmentClient.getAllGarmentsWithProperties(),
            RawMaterialClient.getAllAcceptableRawMaterials()
        ])
        .then(xs => {
            this.countries = xs[0];
            this.garments = xs[1];
            this.garmentsWithProperties = xs[2];
            this.rawMaterials = xs[3];
        })
    }

}
