
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import Chart from '@/views/calculator/components/chart.vue';
import LineChart from '@/components/lineChart.vue';
import BarChart from '@/components/barChart.vue';
import BarChartHorizontal from '@/components/barChartHorizontal.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: { 
        Chart,
        LineChart,
        BarChart,
        BarChartHorizontal
    },
})
export default class Overview extends Vue {

    overviewModel: OM.OverviewVM = new OM.OverviewVM();
    availableYears: string[] = [];
    selectedYear: string = "";

    tonnesVisualization: boolean = false;
    pieChartKey: number = 0;
    lineChartKey: number = 0;
    premises_barChartKey: number = 0;
    scopes_barChartKey: number = 0;
    barChartHorizontalKey: number = 0;
    premisesColors: string[] = [];
    loaded: boolean = false;

    created() {
        this.init(null);
    }

    init(selectedYearNumber: number | null) {
        this.availableYears = [];
        this.availableYears.push("-- All years")

        ActivityDataRecorderClient.getOverviewData(selectedYearNumber)
        .then(x => {
            this.overviewModel = x;

            this.selectedYear = this.overviewModel.selectedYear ? this.overviewModel.selectedYear.toString() : "-- All years";
            this.overviewModel.availableYears.forEach(element => {
                this.availableYears.push(element.toString());
            });

            this.pieChartKey = Math.random();
            this.lineChartKey = Math.random();
            this.premises_barChartKey = Math.random();
            this.scopes_barChartKey = Math.random();
            this.barChartHorizontalKey = Math.random();

            this.overviewModel.premisesTotals.forEach(element => {
                this.premisesColors.push(this.$utils.getHexColor());
            });

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get scopePercentagePieChart() {
        var ris = new OM.SummaryChartVM();

        var scope1 = new OM.SummaryChartSectionVM();
        scope1.sectionName = VM.Scopes.Scope1;
        scope1.percentage = (this.overviewModel.totalEmissionsScope1 * 100) / this.overviewModel.totalEmissions;
        scope1.colorHex = VM.ScopesHexColors.Scope1;

        var scope2 = new OM.SummaryChartSectionVM();
        scope2.sectionName = VM.Scopes.Scope2;
        scope2.percentage = (this.overviewModel.totalEmissionsScope2 * 100) / this.overviewModel.totalEmissions;
        scope2.colorHex = VM.ScopesHexColors.Scope2;

        var scope3 = new OM.SummaryChartSectionVM();
        scope3.sectionName = VM.Scopes.Scope3;
        scope3.percentage = (this.overviewModel.totalEmissionsScope3 * 100) / this.overviewModel.totalEmissions;
        scope3.colorHex = VM.ScopesHexColors.Scope3;

        ris.chartSections.push(scope1);
        ris.chartSections.push(scope2);
        ris.chartSections.push(scope3);

        return ris;
    }

    get emissionsByMonth() {
        this.lineChartKey = Math.random();
        
        if(!this.tonnesVisualization)
            return this.overviewModel.emissionsByMonth;

        var ris: { [key: string]: number; } = {};
        var keys = Object.keys(this.overviewModel.emissionsByMonth);
        keys.forEach(element => {
            ris[element] = this.overviewModel.emissionsByMonth[element] / 1000;
        });

        return ris;
    }

    get premisesPercentagePieChart() {
        var ris = new OM.SummaryChartVM();
        const sumsByKey = {};

        Object.entries(this.overviewModel.premisesEmissionsByScopes).forEach(([key, items]) => {
            sumsByKey[key] = items.reduce((sum, item) => sum + item.value, 0);
        });

        const totalSum = Object.values(sumsByKey).reduce((acc, value) => parseFloat(acc.toString()) + parseFloat(value.toString()), 0);
        var premises = Object.keys(sumsByKey);

        var i = 0;
        premises.forEach(element => {
            var prem = new OM.SummaryChartSectionVM();
            prem.sectionName = element;
            prem.colorHex = this.premisesColors[i];
            prem.percentage = (sumsByKey[element] * 100) / parseFloat(totalSum.toString());
            ris.chartSections.push(prem);

            i++;
        });

        return ris;
    }

    get premisesEmissionsByScopes() {
        this.premises_barChartKey = Math.random();

        if(!this.tonnesVisualization)
            return this.overviewModel.premisesEmissionsByScopes;

        var ris: { [key: string]: OM.KeyValuePairOfStringAndDouble[]; } = {};
        var keys = Object.keys(this.overviewModel.premisesEmissionsByScopes);
        keys.forEach(key => {
            var valuesList: OM.KeyValuePairOfStringAndDouble[] = [];
            this.overviewModel.premisesEmissionsByScopes[key].forEach(element => {
                var tonnesElement = {...element};
                tonnesElement.value = tonnesElement.value / 1000;
                valuesList.push(tonnesElement);
            });

            ris[key] = valuesList;
        });

        return ris;
    }
    get scopesEmissionsByPremises() {
        this.scopes_barChartKey = Math.random();

        if(!this.tonnesVisualization)
            return this.overviewModel.scopesEmissionsByPremises;

        var ris: { [key: string]: OM.KeyValuePairOfStringAndDouble[]; } = {};
        var keys = Object.keys(this.overviewModel.scopesEmissionsByPremises);
        keys.forEach(key => {
            var valuesList: OM.KeyValuePairOfStringAndDouble[] = [];
            this.overviewModel.scopesEmissionsByPremises[key].forEach(element => {
                var tonnesElement = {...element};
                tonnesElement.value = tonnesElement.value / 1000;
                valuesList.push(tonnesElement);
            });

            ris[key] = valuesList;
        });

        return ris;
    }

    get categoriesEmissionsByPremises() {
        this.barChartHorizontalKey = Math.random();

        if(!this.tonnesVisualization)
            return this.overviewModel.categoriesEmissionsByPremises;

        var ris: { [key: string]: OM.KeyValuePairOfStringAndDouble[]; } = {};
        var keys = Object.keys(this.overviewModel.categoriesEmissionsByPremises);
        keys.forEach(key => {
            var valuesList: OM.KeyValuePairOfStringAndDouble[] = [];
            this.overviewModel.categoriesEmissionsByPremises[key].forEach(element => {
                var tonnesElement = {...element};
                tonnesElement.value = tonnesElement.value / 1000;
                valuesList.push(tonnesElement);
            });

            ris[key] = valuesList;
        });

        return ris;
    }

    updateTimePeriod() {
        if(this.selectedYear != "-- All years")
            this.init(parseInt(this.selectedYear));
        else
            this.init(null);
    }

}
